import React from "react";
import Navbar from "./global-components/navbar1";
import PageHeader from "./global-components/page-header";
import ProductDetails from "./shop-components/shop-details";
import Footer from "./global-components/footer";
import usePropertyDetail from "../hooks/usePropertyDetail";
import ProductSlider from './shop-components/product-slider-v1';
import { useTranslation } from "react-i18next";

const Product_Details = () => {
  const { t } = useTranslation();
  const { property, isLoading } = usePropertyDetail();
  return (
    <div>
      <Navbar />
      <PageHeader headertitle={t("ProductDetail")} customclass="mb-0" t={t} />
      <ProductSlider property={property}/>
      <ProductDetails property={property} isLoading={isLoading} t={t} />
      <Footer t={t} />
    </div>
  );
};

export default Product_Details;
