import React from 'react';
import Navbar from './global-components/navbar1';
import PageHeader from './global-components/page-header';
import BlogGrid from './blog-components/blog-grid';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import { useTranslation } from 'react-i18next';

const BlogGridPage = () => {
    const { t } = useTranslation();
    return <div>
        <Navbar />
        <PageHeader headertitle="Blog Grid" t={t}/>
        <BlogGrid />
        <CallToActionV1 />
        <Footer t={t} />
    </div>
}

export default BlogGridPage

