import { Slider, Checkbox, Select, InputNumber } from "antd";
import React from "react";

const Sidebar = ({
  propertyTypes,
  businessType,
  statesTypes,
  handleChangeCheckBox,
  handleChangeLocation,
  setLocation,
  cities,
  handleChangePrice,
  priceRange,
  t,
}) => {
  const { Option } = Select;
  return (
    <div className="mb-100 sticky-sidebar">
      <aside className="sidebar ltn__shop-sidebar">
        <h3 className="mb-10">{t("AdvanceInformation")}</h3>
        {/* Advance Information widget */}
        <div className="widget ltn__menu-widget">
          <h4 className="ltn__widget-title">{t("SearchLocation")}</h4>
          <Select
            showSearch
            optionFilterProp="children"
            style={{ width: "100%" }}
            notFoundContent={t("TypedCity")}
            onSearch={(value) => setLocation(value)}
            onChange={handleChangeLocation}
          >
            {cities?.map((city, key) => (
              <Option className="options-select" key={key} value={city.id}>
                {city.name}, {city.state.name}, {city.state.country.name}
              </Option>
            ))}
          </Select>
          <hr />
          <h4 className="ltn__widget-title">{t("Category")}</h4>
          <ul>
            {businessType?.map((item) => (
              <div key={item.id}>
                <Checkbox
                  className="checkbox-sidebar"
                  name="businessTypeId"
                  onChange={handleChangeCheckBox}
                  value={item.id}
                >
                  {item.name}
                </Checkbox>
                <br />
              </div>
            ))}
          </ul>
          <hr />
          <h4 className="ltn__widget-title">{t("PropertyType")}</h4>
          <ul>
            {propertyTypes?.map((item) => (
              <div key={item.id}>
                <Checkbox
                  name="propertyTypeId"
                  className="checkbox-sidebar"
                  onChange={handleChangeCheckBox}
                  value={item.id}
                >
                  {item.name}
                </Checkbox>
                <br />
              </div>
            ))}
          </ul>
          <hr />
          <h4 className="ltn__widget-title">{t("EstateType")}</h4>
          <ul>
            {statesTypes?.map((item) => (
              <div key={item.id}>
                <Checkbox
                  name="stateTypeId"
                  className="checkbox-sidebar"
                  onChange={handleChangeCheckBox}
                  value={item.id}
                >
                  {item.name}
                </Checkbox>
                <br />
              </div>
            ))}
          </ul>
          <hr />
          <div className="widget--- ltn__price-filter-widget">
            <h4 className="ltn__widget-title ltn__widget-title-border---">
              {t("PriceRange")}
            </h4>
            <Slider
              handleStyle={{ borderColor: "#1FAED5" }}
              trackStyle={{ backgroundColor: "#1B3053" }}
              defaultValue={[0,0]}
              range 
              onChange={handleChangePrice}
              min={0}
              max={1000000}
              // step={0.01}
            />
            <div className="row">
              <div className="col-md-6">
                <InputNumber
              
                  style={{ margin: "0 16px" }}
                  value={priceRange.min}
                  onChange={handleChangePrice}
                />
              </div>
              <div className="col-md-6">
                <InputNumber
                  style={{ margin: "0 16px" }}
                  value={priceRange.max}
                  onChange={handleChangePrice}
                />
              </div>
            </div>
          </div>
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;
