import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Language = () => {
  const { i18n, t } = useTranslation();

  // ** Vars
  const langObj = {
    en: t("English"),
    es: t("Spanish"),
  };

  // ** Function to switch Language
  const handleLangUpdate = (e, lang) => {
    e.preventDefault();
    i18n.changeLanguage(lang);
  };

  return (
    <div className="ltn__drop-menu ltn__currency-menu ltn__language-menu">
      <ul>
        <li>
          <Link to="/" className="dropdown-toggle">
            <span className="active-currency">{langObj[i18n.language]}</span>
          </Link>
          <ul>
            <li className="items-language" onClick={(e) => handleLangUpdate(e, "es")}>{t("Spanish")}</li>
            <li className="items-language" onClick={(e) => handleLangUpdate(e, "en")}>{t("English")}</li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default Language;
