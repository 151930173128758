import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query"
import { getByText } from "../services/api/cities";
import get from "../services/api/properties";

const usePropertyFilters = (t) => {
  const [propertyType, setPropertyType] = useState([]);
  const [businessType, setBusinessType] = useState([]);
  const [stateType, setStateType] = useState([]);
  const [city, setCity] = useState(0);
  const [location, setLocation] = useState("")

  const [priceRange, setPriceRange] = useState({ min: 0, max: 1000000})

  const queryClient = useQueryClient()

  const handleChangeCheckBox = async (value) => {
    let propertyTypeId = propertyType;
    let businessTypeId = businessType;
    let stateTypeId = stateType;
    const { target } = value;

    if (target.name === "propertyTypeId") {
      if (target.checked) {
        propertyTypeId.push(target.value);
        setPropertyType(propertyTypeId);
      } else {
        let removeItemIndex = propertyTypeId.indexOf(target.value);
        if (removeItemIndex !== -1) propertyTypeId.splice(removeItemIndex, 1);
        setPropertyType(propertyTypeId);
      }
    }
    if (target.name === "businessTypeId") {
      if (target.checked) {
        businessTypeId.push(target.value);
        setBusinessType(businessTypeId);
      } else {
        let removeItemIndex = businessTypeId.indexOf(target.value);
        if (removeItemIndex !== -1) businessTypeId.splice(removeItemIndex, 1);
        setBusinessType(businessTypeId);
      }
    }
    if (target.name === "stateTypeId") {
      if (target.checked) {
        stateTypeId.push(target.value);
        setStateType(stateTypeId);
      } else {
        let removeItemIndex = stateTypeId.indexOf(target.value);
        if (removeItemIndex !== -1) stateTypeId.splice(removeItemIndex, 1);
        setBusinessType(stateTypeId);
      }
    }
    const payload = {
      propertyTypeId: propertyTypeId.length > 0 ? propertyTypeId : undefined,
      businessTypeId: businessTypeId.length > 0 ? businessTypeId : undefined,
      stateTypeId: stateTypeId.length > 0 ? stateTypeId : undefined,
      cityId: city !== 0 ? city : undefined
    };

    return await get(payload)
  };

  const { mutate: handleChange, isLoading: isLoadingMut} = useMutation({
    mutationFn: handleChangeCheckBox,
    onSuccess: data => {
        queryClient.setQueryData(['properties'], () => {return data})
    }
  })

  const handleChangeLocation = async (value) => {
    const payload = {
      cityId: value
    };
    setCity(value)
    return await get(payload)
  }

  const { mutate: handleChangeSelect, isLoading: isLoadingSelect} = useMutation({
    mutationFn: handleChangeLocation,
    onSuccess: data => {
        queryClient.setQueryData(['properties'], () => {return data})
    }
  })

  const { data: cities } = useQuery({
    queryKey: ['cities'],
    enabled: location.length >= 3,
    queryFn: async () => {
      return getByText(location)
    }
  })

  const handleChangePrice = (values) => {
   setPriceRange({ min: values[0], max: values[1] })
  }

  return {
    handleChange,
    isLoadingMut,
    handleChangeSelect,
    isLoadingSelect,
    setLocation,
    cities,
    handleChangePrice,
    priceRange
  };
};

export default usePropertyFilters;
