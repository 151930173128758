import React from "react";
const ApartmentPlans = ({ plans, CustomClass, t }) => {
  return (
    <div className={"ltn__apartments-plan-area pt-115--- pb-70 " + CustomClass}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title-area ltn__section-title-2--- text-center">
              <h1 className="section-title">{t("ApartmentPlans")}</h1>
            </div>
            <div className="ltn__tab-menu ltn__tab-menu-3 ltn__tab-menu-top-right-- text-uppercase--- text-center">
              <div className="nav">
                {plans?.length > 0 &&
                  plans.map((item, index) => (
                    <a
                      key={index}
                      className={index === 0 ? "active show" : ""}
                      data-bs-toggle="tab"
                      href={`#liton_tab_${item?.id}`}
                    >
                      {item?.name}
                    </a>
                  ))}
              </div>
            </div>
            <div className="tab-content">
              {plans?.length > 0 &&
                plans.map((item, index) => (
                  <div
                    key={index}
                    className={
                      index === 0
                        ? "tab-pane fade active show"
                        : "tab-pane fade"
                    }
                    id={`liton_tab_${item.id}`}
                  >
                    <div className="ltn__apartments-tab-content-inner">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="apartments-plan-info ltn__secondary-bg text-color-white">
                            <h2>{item?.name}</h2>
                            <p>{item?.description}</p>
                            <div className="apartments-info-list apartments-info-list-color mt-40">
                              <ul>
                                <li>
                                  <label>{t("Type")}</label>{" "}
                                  <span>{item?.typeName}</span>
                                </li>
                                <li>
                                  <label>{t("TotalArea")}</label>{" "}
                                  <span>{item?.totalArea}</span>
                                </li>
                                <li>
                                  <label>{t("PriceForm")}</label>{" "}
                                  <span>{item?.price}</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="apartments-plan-img">
                            <img src={item?.path.split(",")[0]} alt="#" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApartmentPlans;
