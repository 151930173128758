import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { create } from "../services/api/contactUs";
import useNotification from "../utils/notifications";

const useContactUs = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { showNotification } = useNotification();

  const sendInformation = async () => {
    const response = await create(form.getFieldsValue());
    if (response.success) showNotification("success", t("MessageSuccessSend"));
    else showNotification("error", t("MessageErrorSend"));
    form.resetFields();
  };

  return {
    form,
    sendInformation,
  };
};

export default useContactUs;
