import React from 'react';
import Navbar from './global-components/navbar1';
import PageHeader from './global-components/page-header';
import BlogDetails from './blog-components/blog-details';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import { useTranslation } from 'react-i18next';

const BlogDetailsPage = () => {
    const { t } = useTranslation();
    return <div>
        <Navbar />
        <PageHeader headertitle="News Details" t={t}/>
        <BlogDetails />
        <CallToActionV1 />
        <Footer t={t} />
    </div>
}

export default BlogDetailsPage

