import React from "react";
import { Link } from "react-router-dom";

const MainFocus = ({ customClassProp, businessType, t }) => {
  let customClass = customClassProp ? customClassProp : "";

  return (
    <div className={customClass}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title-area ltn__section-title-2--- text-center">
              <h1 className="section-title">{t("OurMainFocus")}</h1>
            </div>
          </div>
        </div>
        <div className="row ltn__custom-gutter--- justify-content-center go-top">
          {businessType.length > 0 &&
            businessType.filter(item => item.id !== 3).map((item) => (
              <div className="col-lg-4 col-sm-6 col-12" key={item.id}>
                <div className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                  <div className="ltn__feature-icon">
                    <i className={`${item.icon}`} />
                  </div>
                  <div className="ltn__feature-info">
                    <h4>
                      <Link
                        to={{
                          pathname: "/shop",
                          state: { businessTypeId: [item.id] },
                        }}
                      >
                        {item.id === 1 ? t("RentTitle") : item.id === 2 ? t("SellTitle") : t("InvestmentTitle")}
                      </Link>
                    </h4>
                    <p>{item.id === 1 ? t("RentDescription") : item.id === 2 ? t("SellDescription") : t("InvestmentDescription")}</p>
                    <Link
                      className="ltn__service-btn"
                      to={{
                        pathname: "/shop",
                        state: { businessTypeId: [item.id] },
                      }}
                    >
                      {item.id === 1 ? t("RentFooter") : item.id === 2 ? t("SellFooter") : t("InvestmentFooter")} <i className="flaticon-right-arrow" />
                    </Link>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default MainFocus;
