import React from "react";
import { Link } from "react-router-dom";
import useBusinessType from "../../hooks/useBussinesType";
import useProperty from "../../hooks/useProperty";
import usePropertyType from "../../hooks/usePropertyType";
import useStateType from "../../hooks/useStateType";
import Sidebar from "./shop-sidebar";
import { Row, Col } from "antd";
import usePropertyFilters from "../../hooks/usePropertyFilters";
import usePagination from "../../hooks/usePagination";
import useWishList from "../../hooks/useWishList";
import { useConfiguration } from "../../context/configurations";
import ModalWishList from "./modal-wishList";

const ShopGridV1 = ({ t }) => {
  const { properties } = useProperty();
  const { propertyTypes } = usePropertyType();
  const { businessType } = useBusinessType();
  const { statesTypes } = useStateType();
  const {
    handleChange,
    handleChangeSelect,
    setLocation,
    cities,
    handleChangePrice,
    priceRange,
  } = usePropertyFilters(t);
  const { nextOrPrev, actualPage } = usePagination();
  const { addWishList } = useWishList();
  const { open, setOpen } = useConfiguration();

  return (
    <div>
      <div className="ltn__product-area ltn__product-gutter">
        <div className="container">
          <Row gutter={16}>
            <Col xs={24} sm={24} md={8} xl={8}>
              <Sidebar
                propertyTypes={propertyTypes}
                businessType={businessType}
                statesTypes={statesTypes}
                handleChangeCheckBox={handleChange}
                handleChangeLocation={handleChangeSelect}
                setLocation={setLocation}
                cities={cities}
                handleChangePrice={handleChangePrice}
                priceRange={priceRange}
                t={t}
              />
            </Col>
            <Col xs={24} sm={24} md={16} xl={16}>
              <div className="tab-content">
                <div
                  className="tab-pane fade active show"
                  id="liton_product_grid"
                >
                  <div className="ltn__product-tab-content-inner ltn__product-grid-view">
                    <div className="row">
                      {/* ltn__product-item */}
                      {properties?.map((item, index) => (
                        <div className="col-xl-6 col-sm-6 col-12" key={index}>
                          <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
                            <div className="product-img go-top">
                              <Link to={`/product-details/${item.id}`}>
                                <img src={item?.images[0]?.path} alt="/" />
                              </Link>
                            </div>
                            <div className="product-info">
                              <div className="product-badge">
                                <ul>
                                  <li className="sale-badg">
                                    {item.businessType.name}
                                  </li>
                                </ul>
                              </div>
                              <h2 className="product-title go-top">
                                <Link to={`/product-details/${item.id}`}>
                                  {item.title}
                                </Link>
                              </h2>
                              <div className="product-img-location go-top">
                                <ul>
                                  <li>
                                    <Link to="/contact">
                                      <i className="flaticon-pin" />{" "}
                                      {item?.city?.name},{" "}
                                      {item?.city?.state?.name}
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                              <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
                                <li>
                                  <span>{item?.roomsNumber} </span>
                                  {t("Bedrooms")}
                                </li>
                                <li>
                                  <span>{item?.bathroomsNumber} </span>
                                  {t("Bath")}
                                </li>
                                <li>
                                  <span>{item?.privateArea} </span>
                                  Square Ft
                                </li>
                              </ul>
                              
                              <div
                                className="product-hover-action"
                                onClick={() => addWishList(item.id)}
                              >
                                <ul>
                                  <li className="wishlist-button">
                                    <span title="Agregar">
                                      <i className="flaticon-heart-1" />
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="product-info-bottom">
                              <div className="product-price">
                                <span>{item?.price} USD</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="ltn__pagination-area text-center">
                <div className="ltn__pagination">
                  <ul>
                    {actualPage > 0 && (
                      <li onClick={() => nextOrPrev("prev")}>
                        <p>
                          <i className="fas fa-angle-left" />
                        </p>
                      </li>
                    )}
                    {properties?.length === 10 && (
                      <li onClick={() => nextOrPrev("next")}>
                        <p>
                          <i className="fas fa-angle-right" />
                        </p>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {open && <ModalWishList setOpen={setOpen} open={open} t={t} />}
    </div>
  );
};

export default ShopGridV1;
