import { useState } from "react";
import { useQuery } from "react-query";
import get from "../services/api/stateType";

const useStateType = () => {
  const [statesTypes, setStatesTypes] = useState([]);

  const { isLoading } = useQuery("stateTypes", {
    queryFn: get,
    onSuccess: (data) => {
      setStatesTypes(data);
    }
  });

  return {
    statesTypes,
    isLoading,
  };
};

export default useStateType;
