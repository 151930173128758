import React from "react";
import { Form, Select, Row } from "antd";
const Banner = ({
  propertyTypes,
  statesTypes,
  businessType,
  homeFilterData,
  searchProperties,
  cities, 
  setLocation,
  t,
}) => {
  const { Option } = Select;

  return (
    <div className="ltn__slider-area ltn__slider-4">
      <div className="ltn__slide-one-active----- slick-slide-arrow-1----- slick-slide-dots-1----- arrow-white----- ltn__slide-animation-active">
        {/* ltn__slide-item */}
        <div
          className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-4 bg-image bg-overlay-theme-black-60"
          data-bs-bg={"https://cdn1.visiontravel.net/bien-raiz/home/4.jpg"}
        >
          <div className="ltn__slide-item-inner text-left">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 align-self-center">
                  <div className="slide-item-car-dealer-form">
                    <div className="ltn__car-dealer-form-tab">
                      <div className="ltn__tab-menu  text-uppercase">
                        <div className="nav">
                          <a
                            className="active show"
                            data-bs-toggle="tab"
                            href="#ltn__form_tab_1_1"
                          >
                            <i className="fas fa-home" />
                            {t("Estate")}
                          </a>
                        </div>
                      </div>
                      <div className="tab-content">
                        <div
                          className="tab-pane fade active show"
                          id="ltn__form_tab_1_1"
                        >
                          <div className="car-dealer-form-inner">
                            <div className="ltn__car-dealer-form-box row">
                              <Form
                                onFinish={searchProperties}
                                form={homeFilterData}
                                layout="vertical"
                              >
                                <Row>
                                  <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-12 col-sm-12 col-lg-3 col-md-12">
                                    <Form.Item
                                      name="cityId"
                                      label={
                                        <label className="filter-labels">
                                          {t("Location")}
                                        </label>
                                      }
                                    >
                                      <Select
                                        showSearch
                                        optionFilterProp="children"
                                        style={{ width: "100%" }}
                                        notFoundContent={t("TypedCity")}
                                        onSearch={(value) => setLocation(value)}
                                      >
                                        {cities?.map((city, key) => (
                                          <Option
                                            className="options-select"
                                            key={key}
                                            value={city.id}
                                          >
                                            {city.name}, {city.state.name}, {city.state.country.name}
                                          </Option>
                                        ))}
                                      </Select>
                                    </Form.Item>
                                  </div>
                                  <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-12 col-sm-12 col-lg-3 col-md-12">
                                    <Form.Item
                                      name="propertyTypeId"
                                      label={
                                        <label className="filter-labels">
                                          {t("PropertyType")}
                                        </label>
                                      }
                                    >
                                      <Select
                                        showSearch
                                        optionFilterProp="children"
                                        size="large"
                                      >
                                        {propertyTypes?.map((item, index) => (
                                          <Option
                                            key={index}
                                            value={index + 1}
                                            className="options-select"
                                          >
                                            {item.name}
                                          </Option>
                                        ))}
                                      </Select>
                                    </Form.Item>
                                  </div>
                                  <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-12 col-sm-12 col-lg-3 col-md-12">
                                    <Form.Item
                                      name="stateTypeId"
                                      label={
                                        <label className="filter-labels">
                                          {t("EstateType")}
                                        </label>
                                      }
                                    >
                                      <Select
                                        showSearch
                                        optionFilterProp="children"
                                        size="large"
                                      >
                                        {statesTypes?.map((item, index) => (
                                          <Option
                                            key={index}
                                            value={index + 1}
                                            className="options-select"
                                          >
                                            {item.name}
                                          </Option>
                                        ))}
                                      </Select>
                                    </Form.Item>
                                  </div>
                                  <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-calendar col-12 col-sm-12 col-lg-3 col-md-12">
                                    <Form.Item
                                      name="businessTypeId"
                                      label={
                                        <label className="filter-labels">
                                          {t("BusinessType")}
                                        </label>
                                      }
                                    >
                                      <Select
                                        showSearch
                                        optionFilterProp="children"
                                        size="large"
                                      >
                                        {businessType?.map((item, index) => (
                                          <Option
                                            key={index}
                                            value={index + 1}
                                            className="options-select"
                                          >
                                            {item.name}
                                          </Option>
                                        ))}
                                      </Select>
                                    </Form.Item>
                                  </div>
                                  <div className="wrap-btn-filter">
                                    <button
                                      type="submit"
                                      className="btn-filter"
                                    >
                                      {t("Search")}
                                    </button>
                                  </div>
                                </Row>
                              </Form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
