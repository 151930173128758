import React from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";

const Map = ({ lat, lng }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_API_KEY_MAPS,
  });

  return (
    <React.Fragment>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={{ width: "100%", height: "400px" }}
          center={{
            lat,
            lng,
          }}
          zoom={12}
        >
          <Marker
            position={{
              lat,
              lng,
            }}
          />
        </GoogleMap>
      )}
    </React.Fragment>
  );
};

export default Map;