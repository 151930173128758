import { Form } from "antd";
import { useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import getByText from "../services/api/cities";

const useFormBanner = () => {
  const history = useHistory();
  const [homeFilterData] = Form.useForm();
  const [location, setLocation] = useState("")

  const searchProperties = () => {
    const data = homeFilterData.getFieldsValue();
    const payload = {
      propertyTypeId: data.propertyTypeId ? [data.propertyTypeId] : undefined,
      businessTypeId: data.businessTypeId ? [data.businessTypeId] : undefined,
      stateTypeId: data.stateTypeId ? [data.stateTypeId] : undefined,
      cityId: data.cityId ? data.cityId : undefined,
    };
    history.push({
      pathname: "/shop",
      state: payload,
    });
  };

  const { data: cities } = useQuery({
    queryKey: ['cities'],
    enabled: location.length >= 3,
    queryFn: async () => {
      return getByText(location)
    }
  })

  return {
    homeFilterData,
    searchProperties,
    cities,
    setLocation
  };
};

export default useFormBanner;
