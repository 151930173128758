import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Location from "../section-components/location";

const ShopDetails = ({ property, t }) => {

  useEffect(() => {
    window.scroll(0, 0)
  }, [])
  
  return (
    <div className="ltn__shop-details-area pb-10 mt-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
              <div className="ltn__blog-meta">
                <ul>
                  <li className="ltn__blog-category">
                    <Link className="bg-orange" to="/">
                      {property?.businessType?.name}
                    </Link>
                  </li>
                  <li className="ltn__blog-date">
                    {property?.stateType?.name}
                  </li>
                  <li className="ltn__blog-date">
                    <i className="far fa-calendar-alt" />
                    {property?.creationDate?.split("T")[0]}
                  </li>
                </ul>
              </div>
              <h1>{property?.title}</h1>
              <label>
                <span className="ltn__secondary-color">
                  <i className="flaticon-pin" />
                </span>{" "}
                {`${property?.city?.name}, ${property?.city?.state?.name}`}
              </label>
              <h4 className="title-2">{t("Description")}</h4>
              <p>{property?.description}</p>
              <h4 className="title-2">{t("Detail")}</h4>
              <div className="property-detail-info-list section-bg-1 clearfix mb-60">
                <ul>
                  <li>
                    <label>{t("Property")} ID:</label>{" "}
                    <span>{property?.code}</span>
                  </li>
                  <li>
                    <label>{t("PrivateArea")}: </label>{" "}
                    <span>
                      {property?.privateArea} {property?.measurement?.code}
                    </span>
                  </li>
                  <li>
                    <label>{t("Bedrooms")}:</label>{" "}
                    <span>{property?.roomsNumber}</span>
                  </li>
                  <li>
                    <label>{t("Bath")}:</label>{" "}
                    <span>{property?.bathroomsNumber}</span>
                  </li>
                  <li>
                    <label>{t("YearBuilt")}:</label>{" "}
                    <span>{property?.yearConstruction}</span>
                  </li>
                  <li>
                    <label>{t("EstateType")}:</label>{" "}
                    <span>{property?.stateType?.name}</span>
                  </li>
                </ul>
                <ul>
                  <li>
                    <label>{t("BuildArea")}:</label>{" "}
                    <span>
                      {property?.buildArea} {property?.measurement?.code}
                    </span>
                  </li>
                  <li>
                    <label>{t("GroundArea")}:</label>{" "}
                    <span>
                      {property?.groundArea} {property?.measurement?.code}
                    </span>
                  </li>
                  <li>
                    <label>{t("ServiceRoom")}:</label>{" "}
                    <span>{property?.serviceRoom ? t("Yes") : "No"}</span>
                  </li>
                  <li>
                    <label>{t("Price")}:</label>{" "}
                    <span>
                      {property?.price} {property?.currency?.code}
                    </span>
                  </li>
                  <li>
                    <label>{t("ParkingNumber")}:</label>{" "}
                    <span>{property?.parkingNumber}</span>
                  </li>
                  <li>
                    <label>{t("UtilityRoom")}:</label>{" "}
                    <span>{property?.utilityRoom ? t("Yes") : "No"}</span>
                  </li>
                </ul>
              </div>
              {property?.propertyAmenities?.length > 0 && (
                <>
                  <h4 className="title-2">{t("Amenities")}</h4>
                  <div className="property-detail-feature-list clearfix mb-45">
                    <ul>
                      {property?.propertyAmenities?.map((item) => (
                        <li key={item.id}>
                          <div className="property-detail-feature-list-item">
                            <i className={`${item?.amenities?.icon}`} />
                            <div>
                              <h6>{item?.amenities?.name}</h6>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              )}
              {/* <h4 className="title-2">{t("Gallery")}</h4>
              <div className="ltn__property-details-gallery mb-30">
                <div className="row">
                  <div className="col-md-6">
                    <a
                      href={publicUrl + "assets/img/others/14.jpg"}
                      data-rel="lightcase:myCollection"
                    >
                      <img
                        className="mb-30"
                        src={publicUrl + "assets/img/others/14.jpg"}
                        alt=""
                      />
                    </a>
                    <a
                      href={publicUrl + "assets/img/others/15.jpg"}
                      data-rel="lightcase:myCollection"
                    >
                      <img
                        className="mb-30"
                        src={publicUrl + "assets/img/others/15.jpg"}
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="col-md-6">
                    <a
                      href={publicUrl + "assets/img/others/16.jpg"}
                      data-rel="lightcase:myCollection"
                    >
                      <img
                        className="mb-30"
                        src={publicUrl + "assets/img/others/16.jpg"}
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div> */}

              <h4 className="title-2">{t("Location")}</h4>

              {property && (
                <Location lat={property?.latitude} lng={property?.longitude} />
              )}

              {/* APARTMENTS PLAN AREA START */}
              {property?.constructionPlans?.length > 0 && (
                <div>
                  <h4 className="title-2">{t("ConstructionPlans")}</h4>
                  <div className="ltn__apartments-plan-area product-details-apartments-plan mb-60">
                    <div className="col-lg-12 tab-content-plans">
                      <div className="apartments-plan-detail-img">
                        <img
                          src={property?.constructionPlans[0].path}
                          alt="/"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* APARTMENTS PLAN AREA END */}
              {/* <h4 className="title-2">Property Video</h4>
              <div
                className="ltn__video-bg-img ltn__video-popup-height-500 bg-overlay-black-50 bg- mb-60"
                data-bs-bg={publicUrl + "assets/img/others/5.jpg"}
              >
                <a
                  className="ltn__video-icon-2 ltn__video-icon-2-border---"
                  href="https://www.youtube.com/embed/eWUxqVFBq74?autoplay=1&showinfo=0"
                  data-rel="lightcase:myCollection"
                >
                  <i className="fa fa-play" />
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopDetails;
