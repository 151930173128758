import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { useConfiguration } from "../context/configurations";
import { useUser } from "../context/user";
import { create, get } from "../services/api/wishList";

const useWishList = () => {
  const queryClient = useQueryClient();
  const { user } = useUser();
  const { setOpen } = useConfiguration();
  const history = useHistory();

  const add = async (propertyId) => {
    if(user) {
        const payload = {
          userId: user.id,
          propertyId,
          createdBy: user.email,
        };
        const response = await create(payload);
        if (response.success) {
          setOpen(true);
        }
    } else {
        history.push('/login')
    }
  };

  const { mutate: addWishList, isLoading } = useMutation({
    mutationFn: add,
    onSuccess: (data) => {
      queryClient.setQueryData(["wishList"], () => {
        return data;
      });
    },
  });

  const { data: wishList, isLoading: isLoadingWL } = useQuery("wishList", {
    queryFn: async () => {
      const response = await get({ userId: user.id});
      return response
  },
  enabled: user !== undefined
  });


  return {
    addWishList,
    isLoading,
    wishList,
    isLoadingWL
  };
};

export default useWishList;
