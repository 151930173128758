import React from 'react';
import Navbar from './global-components/navbar1';
import PageHeader from './global-components/page-header';
import Register from './section-components/register';
// import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import { useTranslation } from 'react-i18next';

const RegisterV1 = () => {
    const { t } = useTranslation();
    return <div>
        <Navbar />
        <PageHeader headertitle={t("Account")} subheader={t("Register")} t={t}/>
        <Register t={t} />
        {/* <CallToActionV1 /> */}
        <Footer t={t} />
    </div>
}

export default RegisterV1

