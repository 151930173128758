import React from "react";
import { Link } from "react-router-dom";

const WishList = ({ wishList }) => {
  return (
    <div className="liton__wishlist-area mb-105">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="shoping-cart-inner">
              <div className="shoping-cart-table table-responsive">
                <table className="wishlist-table">
                  <tbody>
                    {wishList &&
                      wishList.map((item, index) => (
                        <tr key={index}>
                          <td className="cart-product-image">
                            <Link to={`/product-details/${item?.property?.id}`}>
                              <img
                                src={item?.property?.images[0]?.path}
                                alt="/"
                              />
                            </Link>
                          </td>
                          <td className="cart-product-info">
                            <h4 className="go-top">
                              <Link to={`/product-details/${item?.property?.id}`}>
                                {item?.property?.title}
                              </Link>
                            </h4>
                          </td>
                          <td className="cart-product-price">
                            {item?.property?.price}{" "}
                            <span>{item?.property?.currency?.code}</span>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WishList;
