import axios from "axios";

export const HYDRA_API = () => {
  let headers = {
    "Accept-Language": "es",
  };

  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers,
  });

  return instance;
};

export const VT_API = (tokenVT) => {
  let headers = {
    "Accept-Language": "es",
    "authorization": `bearer ${tokenVT}`
  };

  const instance = axios.create({
    baseURL: process.env.REACT_APP_VT_API_URL,
    headers,
  });

  return instance;
};

export default HYDRA_API;
