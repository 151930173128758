import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import get from "../services/api/properties";

const usePagination = () => {
  const [actualPage, setActualPage] = useState(0);
  const size = 10;

  const queryClient = useQueryClient();

  const validateAction = async (action) => {
    let payload = { size };

    if (action === "next") {
      payload = { ...payload, page: actualPage + 1 };
      setActualPage(actualPage + 1);
    } else if (action === "prev") {
      payload = { ...payload, page: actualPage - 1 };
      setActualPage(actualPage - 1);
    }
    window.scroll(0, 0)
    return await get(payload);
  };

  const { mutate: nextOrPrev } = useMutation({
    mutationFn: validateAction,
    onSuccess: (data) => {
      queryClient.setQueryData(["properties"], () => {
        return data;
      });
    },
  });

  return {
    nextOrPrev,
    actualPage,
  };
};

export default usePagination;
