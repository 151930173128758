import React from "react";
// import { Link } from "react-router-dom";

const MyAccount = ({ user, logOut, t }) => {
  // let publicUrl = process.env.PUBLIC_URL + "/";
  return (
    <div className="liton__wishlist-area pb-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            {/* PRODUCT TAB AREA START */}
            <div className="ltn__product-tab-area">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="ltn__tab-menu-list mb-50">
                      <div className="nav">
                        <a
                          className="active show"
                          data-bs-toggle="tab"
                          href="#ltn_tab_1_1"
                        >
                          {t("AccountDetails")} <i className="fas fa-user" />
                        </a>
                        {/* <a data-bs-toggle="tab" href="#ltn_tab_1_2">
                          {t("WishList")}
                          <i className="fa-solid fa-heart" />
                        </a> */}
                        {/* <a data-bs-toggle="tab" href="#ltn_tab_1_9">
                          Change Password <i className="fa-solid fa-lock" />
                        </a> */}
                        <a href="/" onClick={logOut}>
                          {t("LogOut")} <i className="fas fa-sign-out-alt" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="tab-content">
                      <div
                        className="tab-pane fade active show"
                        id="ltn_tab_1_1"
                      >
                        <div className="ltn__myaccount-tab-content-inner">
                          <p>{t("GeneralInfoAccount")}</p>
                          <div className="ltn__form-box">
                            <form action="/my-account">
                              <div className="row mb-50">
                                <div className="col-md-6">
                                  <label>{t("Name")}:</label>
                                  <input
                                    type="text"
                                    name="name"
                                    value={user.name}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <label>{t("Lastname")}:</label>
                                  <input
                                    type="text"
                                    name="lastname"
                                    value={user.lastName}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <label>{t("Email")}:</label>
                                  <input
                                    type="text"
                                    name="email"
                                    value={user.email}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <label>{t("UserType")}:</label>
                                  <input
                                    type="text"
                                    name="roleId"
                                    value={user.role.name}
                                  />
                                </div>
                              </div>
                              {/* <fieldset>
                                <legend>Password change</legend>
                                <div className="row">
                                  <div className="col-md-12">
                                    <label>
                                      Current password (leave blank to leave
                                      unchanged):
                                    </label>
                                    <input type="password" name="ltn__name" />
                                    <label>
                                      New password (leave blank to leave
                                      unchanged):
                                    </label>
                                    <input
                                      type="password"
                                      name="ltn__lastname"
                                    />
                                    <label>Confirm new password:</label>
                                    <input
                                      type="password"
                                      name="ltn__lastname"
                                    />
                                  </div>
                                </div>
                              </fieldset>
                              <div className="btn-wrapper">
                                <button
                                  type="submit"
                                  className="btn theme-btn-1 btn-effect-1 text-uppercase"
                                >
                                  Save Changes
                                </button>
                              </div> */}
                            </form>
                          </div>
                        </div>
                      </div>
                      {/* <div className="tab-pane fade" id="ltn_tab_1_2">
                        <div className="ltn__myaccount-tab-content-inner">
                          <div className="ltn__my-properties-table table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Top Property</th>
                                  <th scope="col" />
                                  <th scope="col">Date Added</th>
                                  <th scope="col">Actions</th>
                                  <th scope="col">Delete</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="ltn__my-properties-img go-top">
                                    <Link to="/product-details">
                                      <img
                                        src={
                                          publicUrl +
                                          "assets/img/product-3/2.jpg"
                                        }
                                        alt="/my-account"
                                      />
                                    </Link>
                                  </td>
                                  <td>
                                    <div className="ltn__my-properties-info">
                                      <h6 className="mb-10 go-top">
                                        <Link to="/product-details">
                                          New Apartment Nice View
                                        </Link>
                                      </h6>
                                      <small>
                                        <i className="icon-placeholder" />{" "}
                                        Brooklyn, New York, United States
                                      </small>
                                      <div className="product-ratting">
                                        <ul>
                                          <li>
                                            <a href="/my-account">
                                              <i className="fas fa-star" />
                                            </a>
                                          </li>
                                          <li>
                                            <a href="/my-account">
                                              <i className="fas fa-star" />
                                            </a>
                                          </li>
                                          <li>
                                            <a href="/my-account">
                                              <i className="fas fa-star" />
                                            </a>
                                          </li>
                                          <li>
                                            <a href="/my-account">
                                              <i className="fas fa-star-half-alt" />
                                            </a>
                                          </li>
                                          <li>
                                            <a href="/my-account">
                                              <i className="far fa-star" />
                                            </a>
                                          </li>
                                          <li className="review-total">
                                            {" "}
                                            <a href="/my-account"> ( 95 Reviews )</a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </td>
                                  <td>Feb 22, 2022</td>
                                  <td>
                                    <Link to="/my-account">Edit</Link>
                                  </td>
                                  <td>
                                    <Link tp="/my-account">
                                      <i className="fa-solid fa-trash-can" />
                                    </Link>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="ltn__my-properties-img go-top">
                                    <Link to="/product-details">
                                      <img
                                        src={
                                          publicUrl +
                                          "assets/img/product-3/3.jpg"
                                        }
                                        alt="/my-account"
                                      />
                                    </Link>
                                  </td>
                                  <td>
                                    <div className="ltn__my-properties-info">
                                      <h6 className="mb-10 go-top">
                                        <Link to="/product-details">
                                          New Apartment Nice View
                                        </Link>
                                      </h6>
                                      <small>
                                        <i className="icon-placeholder" />{" "}
                                        Brooklyn, New York, United States
                                      </small>
                                      <div className="product-ratting">
                                        <ul>
                                          <li>
                                            <a href="/my-account">
                                              <i className="fas fa-star" />
                                            </a>
                                          </li>
                                          <li>
                                            <a href="/my-account">
                                              <i className="fas fa-star" />
                                            </a>
                                          </li>
                                          <li>
                                            <a href="/my-account">
                                              <i className="fas fa-star" />
                                            </a>
                                          </li>
                                          <li>
                                            <a href="/my-account">
                                              <i className="fas fa-star-half-alt" />
                                            </a>
                                          </li>
                                          <li>
                                            <a href="/my-account">
                                              <i className="far fa-star" />
                                            </a>
                                          </li>
                                          <li className="review-total">
                                            {" "}
                                            <a href="/my-account"> ( 95 Reviews )</a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </td>
                                  <td>Feb 22, 2022</td>
                                  <td>
                                    <Link to="/my-account">Edit</Link>
                                  </td>
                                  <td>
                                    <Link tp="/my-account">
                                      <i className="fa-solid fa-trash-can" />
                                    </Link>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="ltn__my-properties-img go-top">
                                    <Link to="/product-details">
                                      <img
                                        src={
                                          publicUrl +
                                          "assets/img/product-3/7.jpg"
                                        }
                                        alt="/my-account"
                                      />
                                    </Link>
                                  </td>
                                  <td>
                                    <div className="ltn__my-properties-info">
                                      <h6 className="mb-10 go-top">
                                        <Link to="/product-details">
                                          New Apartment Nice View
                                        </Link>
                                      </h6>
                                      <small>
                                        <i className="icon-placeholder" />{" "}
                                        Brooklyn, New York, United States
                                      </small>
                                      <div className="product-ratting">
                                        <ul>
                                          <li>
                                            <a href="/my-account">
                                              <i className="fas fa-star" />
                                            </a>
                                          </li>
                                          <li>
                                            <a href="/my-account">
                                              <i className="fas fa-star" />
                                            </a>
                                          </li>
                                          <li>
                                            <a href="/my-account">
                                              <i className="fas fa-star" />
                                            </a>
                                          </li>
                                          <li>
                                            <a href="/my-account">
                                              <i className="fas fa-star-half-alt" />
                                            </a>
                                          </li>
                                          <li>
                                            <a href="/my-account">
                                              <i className="far fa-star" />
                                            </a>
                                          </li>
                                          <li className="review-total">
                                            {" "}
                                            <a href="/my-account"> ( 95 Reviews )</a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </td>
                                  <td>Feb 22, 2022</td>
                                  <td>
                                    <Link to="/my-account">Edit</Link>
                                  </td>
                                  <td>
                                    <Link tp="/my-account">
                                      <i className="fa-solid fa-trash-can" />
                                    </Link>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="ltn__pagination-area text-center">
                            <div className="ltn__pagination">
                              <ul>
                                <li>
                                  <Link to="/my-account">
                                    <i className="fas fa-angle-double-left" />
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/my-account">1</Link>
                                </li>
                                <li className="active">
                                  <Link to="/my-account">2</Link>
                                </li>
                                <li>
                                  <Link to="/my-account">3</Link>
                                </li>
                                <li>
                                  <Link to="/my-account">...</Link>
                                </li>
                                <li>
                                  <Link to="/my-account">10</Link>
                                </li>
                                <li>
                                  <Link to="/my-account">
                                    <i className="fas fa-angle-double-right" />
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="tab-pane fade" id="ltn_tab_1_9">
                        <div className="ltn__myaccount-tab-content-inner">
                          <div className="account-login-inner">
                            <form
                              action="/my-account"
                              className="ltn__form-box contact-form-box"
                            >
                              <h5 className="mb-30">Change Password</h5>
                              <input
                                type="password"
                                name="password"
                                placeholder="Current Password*"
                              />
                              <input
                                type="password"
                                name="password"
                                placeholder="New Password*"
                              />
                              <input
                                type="password"
                                name="password"
                                placeholder="Confirm New Password*"
                              />
                              <div className="btn-wrapper mt-0">
                                <button
                                  className="theme-btn-1 btn btn-block"
                                  type="submit"
                                >
                                  Save Changes
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* PRODUCT TAB AREA END */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAccount;
