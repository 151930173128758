import React from "react";
import Navbar from "./global-components/navbar";
import Banner from "./section-components/banner";
import MainFocus from "./section-components/OurMainFocus";
import ProSlider from "./section-components/product-slider-v1";
import ApartmentPlans from "./section-components/apartment-v1";
import VideoV1 from "./section-components/video-v1";
import Amenities from "./section-components/amenities";
import Testimonial from "./section-components/testimonial-v1";
import Footer from "./global-components/footer";
import usePropertyType from "../hooks/usePropertyType";
import useStateType from "../hooks/useStateType";
import useBusinessType from "../hooks/useBussinesType";
import useFormBanner from "../hooks/useFormBanner";
import { useTranslation } from "react-i18next";
import useAmenities from "../hooks/useAmenities";
import usePlans from "../hooks/usePlans";
import useProperty from "../hooks/useProperty";
import { useUser } from "../context/user";

const Home = () => {
  const { t } = useTranslation();

  const { statesTypes } = useStateType();
  const { propertyTypes } = usePropertyType();
  const { businessType } = useBusinessType();
  const { amenities } = useAmenities();
  const { homeFilterData, searchProperties, cities, setLocation } =
    useFormBanner();
  const { plans } = usePlans();
  const { properties } = useProperty();
  const { user, logOut } = useUser()
  return (
    <div>
      <Navbar CustomClass="ltn__header-transparent gradient-color-2" user={user} logOut={logOut} />
      <Banner
        propertyTypes={propertyTypes}
        statesTypes={statesTypes}
        businessType={businessType}
        homeFilterData={homeFilterData}
        searchProperties={searchProperties}
        cities={cities}
        setLocation={setLocation}
        t={t}
      />
      <MainFocus
        customClassProp="ltn__feature-area section-bg-1 pt-120 pb-90 mb-120---"
        businessType={businessType}
        t={t}
      />
      {properties !== undefined && <ProSlider properties={properties} t={t} />}
      <ApartmentPlans plans={plans} t={t} />
      <VideoV1 />
      <Amenities amenities={amenities} t={t} />
      <Testimonial t={t} />
      <Footer t={t} />
    </div>
  );
};

export default Home;
