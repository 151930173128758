import React from "react";
import { Link } from "react-router-dom";

const Amenities = ({ amenities, t }) => {
  return (
    <div className="ltn__category-area ltn__product-gutter section-bg-1--- pt-115 pb-90 go-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title-area ltn__section-title-2--- text-center">
              <h1 className="section-title">{t("Amenities")}</h1>
            </div>
          </div>
        </div>
        <div className="row ltn__category-slider-active--- slick-arrow-1 justify-content-center">
          {amenities.length > 0 &&
            amenities.map((item) => (
              <div className="col-lg-3 col-md-4 col-sm-6 col-6" key={item.id}>
                <div className="ltn__category-item ltn__category-item-5 text-center">
                  <Link
                    to={{
                      pathname: "/shop",
                      state: { amenitiesId: [item.id] },
                    }}
                  >
                    <span className="category-icon">
                      <i className={`${item.icon}`} />
                    </span>
                    <span className="category-title">{item.name}</span>
                    <span className="category-btn">
                      <i className="flaticon-right-arrow" />
                    </span>
                  </Link>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Amenities;
