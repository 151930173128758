import HYDRA_API from "./config/hydraAPI";

export const get = async (payload) => {
  const url = `${process.env.REACT_APP_API_URL}property/get`;

  const result = await HYDRA_API().post(url, payload);
  return result.data.data;
};


export const getApartmentPlans = async () => {
  const url = `${process.env.REACT_APP_API_URL}property/getPlans`;

  const result = await HYDRA_API().get(url);
  return result.data.data;
};

export default get;
