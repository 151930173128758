import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import get from "../services/api/properties";

const usePropertyDetail = () => {

  const { id } = useParams()

  const { data: property, isLoading } = useQuery("property", {
    queryFn: async () => {
        const response = await get({id});
        return response[0]
    },
    enabled: id !== undefined
  });

  return {
    property,
    isLoading,
  };
};

export default usePropertyDetail;
