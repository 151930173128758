import React from 'react';
import Navbar from './global-components/navbar1';
import PageHeader from './global-components/page-header';
import BlogRightSidebar from './blog-components/blog-right-sidebar';
import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from './global-components/footer';
import { useTranslation } from 'react-i18next';

const BlogRightSidebarPage = () => {
    const { t } = useTranslation();
    return <div>
        <Navbar />
        <PageHeader headertitle="Blog Right Sidebar" subheader="Blog" t={t} />
        <BlogRightSidebar />
        <CallToActionV1 />
        <Footer t={t} />
    </div>
}

export default BlogRightSidebarPage

