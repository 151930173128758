import React from "react";

const AboutV4 = ({ t }) => {
  return (
    <div className="ltn__about-us-area pt-120--- pb-90 mt--30 go-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 align-self-center history-section">
            <h1 className="section-title">{t("AboutHistoryTitle")}</h1>
            <p>{t("AboutHistoryP1")}</p>
            <p>{t("AboutHistoryP2")}</p>
            <p>{t("AboutHistoryP3")}</p>
            <p>{t("AboutHistoryP4")}</p>
            <p>{t("AboutHistoryP5")}</p>
          </div>

          <div className="col-lg-6 align-self-center">
            <div className="about-us-img-wrap about-img-left">
              <img
                src={"https://cdn1.visiontravel.net/bien-raiz/about/1.jpg"}
                alt="About Us "
              />
            </div>
          </div>
          <div className="col-lg-6 align-self-center mt-5">
            <div className="about-us-info-wrap">
              <div className="section-title-area ltn__section-title-2--- mb-20">
                <p>{t("AboutDescriptionP1")}</p>
                <p>{t("AboutDescriptionP2")}</p>
                <p>{t("AboutDescriptionP3")}</p>
                <p className="offer-description">{t("AboutDescriptionP4")}</p>
              </div>
              <ul className="ltn__list-item-half">
                <li>{t("AboutDescriptionLi1")}</li>
                <li>{t("AboutDescriptionLi2")}</li>
                <li>{t("AboutDescriptionLi3")}</li>
                <li>{t("AboutDescriptionLi4")}</li>
                <li>{t("AboutDescriptionLi5")}</li>
                <li>{t("AboutDescriptionLi6")}</li>
                <li>{t("AboutDescriptionLi7")}</li>
              </ul>
            </div>
          </div>

          <div className="col-lg-6 align-self-center mt-5">
            <div className="about-us-info-wrap">
              <div className="section-title-area ltn__section-title-2--- mb-20">
			  	<h6  className="section-subtitle section-subtitle-2 ltn__secondary-color">
                  {t("AboutAgentTitle")}
                </h6>
                <h1 className="section-title">
				{t("AboutAgentSubTitle")}
                </h1>
                <p>
                 {t("AboutAgentP1")}
                </p>
                <p>
                 {t("AboutAgentP2")}
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-6 align-self-center  mt-5">
            <div className="about-us-img-wrap about-img-left">
              <img
                src={"https://cdn1.visiontravel.net/bien-raiz/about/2.jpg"}
                alt="About Us "
              />
            </div>
          </div>
          {/* <div className="col-lg-6 align-self-center  mt-5">
            <div className="about-us-img-wrap about-img-left">
              <img
                src={"https://cdn1.visiontravel.net/bien-raiz/about/6.jpg"}
                alt="About Us "
              />
            </div>
          </div>
          <div className="col-lg-6 align-self-center  mt-5">
            <div className="about-us-info-wrap">
              <div className="section-title-area ltn__section-title-2--- mb-20">
                <h1 className="section-title">
                  The Leading Real Estate Rental Marketplace<span>.</span>
                </h1>
                <p>
                  Over 39,000 people work for us in more than 70 countries all
                  over the This breadth of global coverage, combined with
                  specialist services
                </p>
              </div>
              <ul className="ltn__list-item-half clearfix">
                <li>
                  <i className="flaticon-home-2" />
                  Smart Home Design
                </li>
                <li>
                  <i className="flaticon-mountain" />
                  Beautiful Scene Around
                </li>
                <li>
                  <i className="flaticon-heart" />
                  Exceptional Lifestyle
                </li>
                <li>
                  <i className="flaticon-secure" />
                  Complete 24/7 Security
                </li>
              </ul>
              <div className="ltn__callout bg-overlay-theme-05  mt-30">
                <p>
                  "Enimad minim veniam quis nostrud exercitation <br />
                  llamco laboris. Lorem ipsum dolor sit amet"{" "}
                </p>
              </div>
              <div className="btn-wrapper animated">
                <Link to="/service" className="theme-btn-1 btn btn-effect-1">
                  OUR SERVICES
                </Link>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default AboutV4;
