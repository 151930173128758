/*eslint-disable*/
import React, { createContext, useState, useContext } from "react";

export const ConfigurationContext = createContext();

export const ConfigurationProvider = ({ children }) => {

  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(!open);
  };

  return (
    <ConfigurationContext.Provider
      value={{
        open,
        setOpen,
        showModal
      }}
    >
      {children}
    </ConfigurationContext.Provider>
  );
};

export const useConfiguration = () => {
  return useContext(ConfigurationContext);
};
