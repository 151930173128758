import React, { useEffect } from "react";
import Navbar from "./global-components/navbar1";
import PageHeader from "./global-components/page-header";
import Wishlist from "./section-components/wishlist";
// import CallToActionV1 from './section-components/call-to-action-v1';
import Footer from "./global-components/footer";
import { useTranslation } from "react-i18next";
import useWishList from "../hooks/useWishList";
import { useUser } from "../context/user";

const WishlistPage = () => {
  const { t } = useTranslation();
  const { wishList } = useWishList();
  const { user, logOut } = useUser()
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div>
      <Navbar user={user}  logOut={logOut}/>
      <PageHeader headertitle={t("WishList")} t={t} />
      <Wishlist wishList={wishList} t={t} />
      {/* <CallToActionV1 /> */}
      <Footer t={t} />
    </div>
  );
};

export default WishlistPage;
