import { useState } from "react";
import { useQuery } from "react-query";
import get from "../services/api/amenities";

const useAmenities = () => {
  const [amenities, setAmenities] = useState([]);

  const { isLoading } = useQuery("amenities", {
    queryFn: get,
    onSuccess: (data) => {
        setAmenities(data);
    },
  });

  return {
    amenities,
    isLoading,
  };
};

export default useAmenities;
