/*eslint-disable*/
import { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import get from "../services/api/properties";

const useProperty = () => {
  const queryClient = useQueryClient();

  const { state } = useLocation();

  const { data: properties, isLoading } = useQuery("properties", {
    queryFn: get,
    enabled: state === undefined
  });

  const { mutate: getByFormBanner } = useMutation({
    mutationFn: get,
    onSuccess: (data) => {
      queryClient.setQueryData(["properties"], () => {
        return data;
      });
    },
  });

  useEffect(() => {
    getByFormBanner(state);
  }, [state]);

  return {
    properties,
    isLoading,
  };
};

export default useProperty;
