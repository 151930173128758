import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import registerUser from "../services/api/user";
import useNotification from "../utils/notifications";

const useRegister = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { showNotification } = useNotification();
  const history = useHistory();

  const register = async (values) => {
    const payload = {
      name: values.name,
      lastName: values.lastName,
      email: values.email,
      password: values.confirmPassword,
      roleId: 7,
      createdBy: "Service create B2C",
    };
    const response = await registerUser(payload);
    if (response.success) {
      showNotification("success", t("RegisterSuccess"));
      history.push('/login');
    } else {
      showNotification("error", response.message);
    }
    form.resetFields();
  };

  const { mutate: registerCustomer, isLoading } = useMutation({
    mutationFn: register,
    onSuccess: (data) => {
      queryClient.setQueryData(["register"], () => {
        return data;
      });
    },
  });

  return {
    form,
    registerCustomer,
    isLoading,
  };
};

export default useRegister;
