import React, { Component } from "react";

class VideoV1 extends Component {
  render() {

    return (
      <div  className="ltn__video-popup-area ltn__video-popup-margin---">
        <div
           className="ltn__video-bg-img ltn__video-popup-height-600--- bg-overlay-black-30 bg-image bg-fixed ltn__animation-pulse1"
          data-bs-bg={"https://cdn1.visiontravel.net/bien-raiz/home/5.jpg"}
        >
          <a
             className="ltn__video-icon-2 ltn__video-icon-2-border---"
            href="https://www.youtube.com/embed/5V6iThrrTvE?autoplay=1&showinfo=0"
            data-rel="lightcase:myCollection"
          >
            <i  className="fa fa-play" />
          </a>
        </div>
      </div>
    );
  }
}

export default VideoV1;
