/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import { Carousel } from "@trendyol-js/react-carousel";
const Featured = ({ properties, t }) => {

  return (
    <div className="slider-featured  mt-5 mb-5">
      <div className="row">
        <div className="col-lg-12">
          <div className="section-title-area ltn__section-title-2--- text-center">
            <h1 className="section-title">{t("Featured")}</h1>
          </div>
        </div>
      </div>
      <Carousel
        responsive={true}
        swiping={true}
        slide={2}
        show={screen.width <= 600 ? 1 : screen.width > 600 && screen.width <= 1400 ? 3 : 4 }
      >
        {properties
          .filter((item) => item.id <= 35)
          .map((item, index) => (
            <div className="col-lg-12" key={index}>
              <div className="ltn__product-item ltn__product-item-4 text-center---">
                <div className="product-img go-top">
                  <Link to={`/product-details/${item.id}`}>
                    <img
                      src={item.images.length > 0 ? item.images[0].path : ""}
                      alt="#"
                    />
                  </Link>
                  <div className="product-badge">
                    <ul>
                      <li className="sale-badge bg-green">
                        {item.businessType.name}
                      </li>
                    </ul>
                  </div>
                  <div className="product-img-location-gallery">
                    <div className="product-img-location">
                      <ul>
                        <li>
                          <Link to={`/product-details/${item.id}`}>
                            <i className="flaticon-pin" /> {item.city.name},
                            {item.city.state.name}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="product-info">
                  <div className="product-price">
                    <span>
                      {item.price}
                      <label>{item.currency.code}</label>
                    </span>
                  </div>
                  <h2 className="product-title go-top">
                    <Link to={`/product-details/${item.id}`}>{item.title}</Link>
                  </h2>
                  <ul className="ltn__list-item-2 ltn__list-item-2-before">
                    <li>
                      <span>
                        {item.roomsNumber} <i className="flaticon-bed" />
                      </span>
                      {t("Bedrooms")}
                    </li>
                    <li>
                      <span>
                        {item.bathroomsNumber} <i className="flaticon-clean" />
                      </span>
                      {t("Bath")}
                    </li>
                    <li>
                      <span>
                        {item.privateArea}{" "}
                        <i className="flaticon-square-shape-design-interface-tool-symbol" />
                      </span>
                      {item.measurement.code}
                    </li>
                  </ul>
                </div>
                {/* <div className="product-info-bottom">
                  <div className="real-estate-agent">
                    <div className="agent-img go-top">
                      <Link to="/team-details">
                        <img
                          src={publicUrl + "assets/img/blog/author.jpg"}
                          alt="#"
                        />
                      </Link>
                    </div>
                    <div className="agent-brief go-top">
                      <h6>
                        <Link to="/team-details">William Seklo</Link>
                      </h6>
                      <small>Estate Agents</small>
                    </div>
                  </div>
                  <div className="product-hover-action">
                    <ul>
                      <li>
                        <a
                          href="#"
                          title="Quick View"
                          data-bs-toggle="modal"
                          data-bs-target="#quick_view_modal"
                        >
                          <i className="flaticon-expand" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          title="Wishlist"
                          data-bs-toggle="modal"
                          data-bs-target="#liton_wishlist_modal"
                        >
                          <i className="flaticon-heart-1" />
                        </a>
                      </li>
                      <li>
                        <span className="go-top">
                          <Link to="/product-details" title="Product Details">
                            <i className="flaticon-add" />
                          </Link>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
          ))}
      </Carousel>
    </div>
  );
};

export default Featured;
